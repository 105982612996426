.nav-toggle {
  --buttonSize: var(--space2XL);

  position: fixed;
  top: var(--spaceOuter);
  right: var(--spaceOuter);
  width: var(--buttonSize);
  height: var(--buttonSize);
  z-index: 1024;
  display: none;

  @media (--mediaMobile), (max-height: 696px) {
    & {
      display: flex;
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      top: calc(var(--space4XL) * 0.42);
    }
  }
  
}

.nav-toggle__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.nav-toggle__icon {
  --iconSize: var(--spaceXL);

  position: absolute;
  transition-property: opacity, fill;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  opacity: 1;
  transform: rotate(0deg);
  fill: var(--colorTextBody);
  width: var(--iconSize);
  height: var(--iconSize);

  @media (--mediaUseMotion) {
    & {
      transition-delay: 0.1s;
      transition-property: opacity, transform, fill;
    }
  }
}

.nav-toggle__icon--open {
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-delay: 0s;
      transform: rotate(45deg);
    }
  }
}

.nav-toggle__icon--close {
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-delay: 0s;
      transform: rotate(-45deg);
    }
  }
}

.nav-toggle__icon--open.nav-toggle__icon--close {
  opacity: 1;

  @media (--mediaUseMotion) {
    & {
      transition-delay: 0.1s;
      transform: rotate(0deg);
    }
  }
}
