.navbar-work {
  --headerNavFontSize: calc((16 / 16) * 1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  /*width: var(--space4XL);*/
  position: fixed;
  right: var(--spaceOuter);
  top: auto;
  bottom: calc(var(--space4XL)*2.17);
  z-index: 1024;
  text-align: right;

  @media (--mediaLaptop) {
    & {
      bottom: calc(var(--space3XL) * +2.98);
    }
  }

  @media (max-width: 1200px) {
    & {
      bottom: calc(var(--space3XL) * +3);
    }
  }

  @media (--mediaMobile) {
    & {
      left: var(--spaceOuter);
      right: auto;
      --headerNavFontSize: calc((22 / 16) * 1rem);
      text-align: left;
    }
  }

  @media (--mediaMobile), (max-height: 696px) {
    & {
      top: var(--spaceOuter);
      bottom: auto;
    }
  }
}

.navbar-work__nav {
  position: relative;
  top: calc(var(--spaceXL) * +3.8);
  right: calc(var(--spaceS) * -1);
  min-width: 150px;
  width: 100%;

  @media (--mediaMobile), (max-height: 696px) {
    & {
      display: none;
    }
  }
}

.navbar-work__nav-list {
  display: flex;
  flex-direction: column;
  direction: rtl;
  justify-content: flex-end;
}

.navbar-work__nav-link {
  padding: var(--spaceS);
  color: rgb(var(--rgbText) / 0.8);
  font-weight: var(--fontWeightMedium);
  font-size: var(--headerNavFontSize);
  text-decoration: none;
  position: relative;
  transition: color var(--durationS) ease 0.1s;
  line-height: 0.4;
  letter-spacing: -0.7px;
  text-align: right;
  width: fit-content;
  &:hover,
  &:active,
  &:focus,
  &[aria-current='page'] {
    color: var(--colorTextBody);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: var(--spaceS);
    left: var(--spaceS);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-2px);
    transform-origin: right;
  }

  @media (--mediaUseMotion) {
    &::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

  &:hover:after,
  &:active:after,
  &:focus:after,
  &[aria-current='page']:after {
    transform: scaleX(1) translateY(-2px);
    transform-origin: left;
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }
}

.navbar-work__nav-link:nth-child(1),
.navbar-work__nav-link:nth-child(2),
.navbar-work__nav-link:nth-child(3),
.navbar-work__nav-link:nth-child(4),
.navbar-work__nav-link:nth-child(5),
.navbar-work__nav-link:nth-child(6),
.navbar-work__nav-link:nth-child(7),
.navbar-work__nav-link:nth-child(8),
.navbar-work__nav-link:nth-child(9),
.navbar-work__nav-link:nth-child(10),
.navbar-work__nav-link:nth-child(11) {
  &::before {
    font-size: var(--fontSizeBody2XS);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    padding-right: 2px;
    vertical-align: top;
  }   
  &:hover:before {
    font-weight: 500;
  }  
}

/*Autosport*/
.navbar-work__nav-link:nth-child(1) {
  &::before {
    content: '2019';
  }  
}
/*Mulberry*/
.navbar-work__nav-link:nth-child(2) {
  &::before {
    content: '2016';
  }     
}
/*Aviva*/
.navbar-work__nav-link:nth-child(3) {
  &::before {
    content: '2016';
  }    
}
/*Oasis*/
.navbar-work__nav-link:nth-child(4) {
  &::before {
    content: '2016';
  }   
}
/*TKMaxx*/
.navbar-work__nav-link:nth-child(5) {
  &::before {
    content: '2016';
  }    
}
/*Gucci*/
.navbar-work__nav-link:nth-child(6) {
  &::before {
    content: '2014';
  }   
}
/*Goodyear*/
.navbar-work__nav-link:nth-child(7) {
  &::before {
    content: '2014';
  }    
}
/*Sainsbury's*/
.navbar-work__nav-link:nth-child(8) {
  &::before {
    content: '2013';
  }   
}
/*Barbour*/
.navbar-work__nav-link:nth-child(9) {
  &::before {
    content: '2013';
  }    
}
/*Dr Marten's*/
.navbar-work__nav-link:nth-child(10) {
  &::before {
    content: '2013';
  }   
}
/* Various */
.navbar-work__nav-link:nth-child(11) {
  &::before {
    content: '2001';
  }    
}

.navbar-work__nav-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 16;

  @media (--mediaMobile), (max-height: 696px) {
    & {
      flex-direction: row;
      position: absolute;
      bottom: var(--spaceXL);
      left: var(--spaceXL);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      left: var(--spaceL);
      transform: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.navbar-work__nav-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spaceS);
  width: var(--space2XL);
  height: var(--space2XL);
  color: var(--colorTextLight);
  transition: color var(--durationM) var(--bezierFastoutSlowin);

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: rgb(var(--rgbAccent));
  }
}

.navbar-work__nav-icon {
  fill: currentColor;
  transition: fill var(--durationM) ease;
}

.navbar-work__mobile-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(var(--rgbBackground) / 0.9);
  transform: translate3d(0, -100%, 0);
  transition-property: background;
  transition-duration: var(--durationL);
  transition-timing-function: var(--bezierFastoutSlowin);
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(16px);

  @media (--mediaMobile), (max-height: 696px) {
    & {
      display: flex;
    }
  }

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, background;
      transform: translate3d(0, -100%, 0);
    }
  }
}

.navbar-work__mobile-nav--entered {
  transform: translate3d(0, 0, 0);
}

.navbar-work__mobile-nav-link {
  width: 100%;
  font-size: var(--headerNavFontSize);
  text-align: center;
  text-decoration: none;
  color: var(--colorTextBody);
  padding: var(--spaceL);
  opacity: 0;
  transition-property: opacity;
  transition-duration: var(--durationS);
  transition-timing-function: var(--bezierFastoutSlowin);
  position: relative;
  top: calc(var(--spaceM) * -1);

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, calc(var(--spaceXL) * -1), 0);
    }
  }

  @media (max-width: 820px) and (max-height: 420px) {
    & {
      top: auto;
    }
  }

  @media (max-width: 400px), (max-height: 360px) {
    & {
      --headerNavFontSize: calc((18 / 16) * 1rem);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: var(--space3XL);
    left: var(--space3XL);
    height: 4px;
    background: rgb(var(--rgbAccent));
    transform: scaleX(0) translateY(-1px);
    transform-origin: right;
  }

  @media (--mediaUseMotion) {
    &::after {
      transition: transform var(--durationM) var(--bezierFastoutSlowin);
    }
  }

  &:hover:after,
  &:active:after,
  &:focus:after {
    transform: scaleX(1) translateY(-1px);
    transform-origin: left;
  }

  &:focus {
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
    outline: none;
  }

  &:active {
    box-shadow: none;
  }
}

.navbar-work__mobile-nav-link--entered {
  opacity: 1;
  transform: none;
}


.navbar__mobile-nav-link:nth-child(6),
.navbar__mobile-nav-link:nth-child(7),
.navbar__mobile-nav-link:nth-child(8),
.navbar__mobile-nav-link:nth-child(9),
.navbar__mobile-nav-link:nth-child(10),
.navbar__mobile-nav-link:nth-child(11),
.navbar__mobile-nav-link:nth-child(12),
.navbar__mobile-nav-link:nth-child(13),
.navbar__mobile-nav-link:nth-child(14),
.navbar__mobile-nav-link:nth-child(15),
.navbar__mobile-nav-link:nth-child(16) {
  &::before {
    font-size: var(--fontSizeBody2XS);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    padding-right: 2px;
    vertical-align: top;
  }   
  &:hover:before {
    font-weight: 500;
  }  
}

/*Autosport*/
.navbar__mobile-nav-link:nth-child(6) {
  &::before {
    content: '2019';
  }  
}
/*Mulberry*/
.navbar__mobile-nav-link:nth-child(7) {
  &::before {
    content: '2016';
  }     
}
/*Aviva*/
.navbar__mobile-nav-link:nth-child(8) {
  &::before {
    content: '2016';
  }    
}
/*Oasis*/
.navbar__mobile-nav-link:nth-child(9) {
  &::before {
    content: '2016';
  }   
}
/*TKMaxx*/
.navbar__mobile-nav-link:nth-child(10) {
  &::before {
    content: '2016';
  }    
}
/*Gucci*/
.navbar__mobile-nav-link:nth-child(11) {
  &::before {
    content: '2014';
  }   
}
/*Goodyear*/
.navbar__mobile-nav-link:nth-child(12) {
  &::before {
    content: '2014';
  }    
}
/*Sainsbury's*/
.navbar__mobile-nav-link:nth-child(13) {
  &::before {
    content: '2013';
  }   
}
/*Barbour*/
.navbar__mobile-nav-link:nth-child(14) {
  &::before {
    content: '2013';
  }    
}
/*Dr Marten's*/
.navbar__mobile-nav-link:nth-child(15) {
  &::before {
    content: '2013';
  }   
}
/* Various */
.navbar__mobile-nav-link:nth-child(16) {
  &::before {
    content: '2001';
  }    
}
